/*=====================================
    結帳頁面

    Author: ac
    createtime: 2018 / 03 / 19
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import GLOBAL from 'nonnmwebglobal';
import { GlobalStyle , StepBottomBar } from 'stylecomponents';
import styled from 'styled-components';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import productUtil from 'util/productUtil';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    styled Component
--------------------------*/
const ContentWrapper = styled.div`
    padding: 16px 16px 60px 16px;
`;
const Title = styled.div`
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 20px;
    color: #333333;
    border-bottom: 1px solid rgb(220, 220, 220);
`;
const SelectItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
`;
const SelectTitle = styled.div`
    flex-grow: 1;
    height: 44px;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    background-color: ${props => props.active ? '#F75C2F' : 'FAFAFA'};
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    opacity: ${props => props.active ? 1 : 0.5};
    font-size: 15px;
    color: ${props => props.active ? '#FFFFFF' : '#222328'};
    cursor: pointer;
`;
const Instructions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 22px;
    background-color: #9B9B9B;
    border-radius: 2px;
    font-size: 15px;
    color: #FFFFFF;
    cursor: pointer;
`;
const Prompt = styled.div`
    font-size: 12px;
    color: #595A59;
`;

/*--------------------------
    Main Component
--------------------------*/
class CheckoutView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.payCardCart = this.payCardCart.bind(this);
        this.changePaytype = this.changePaytype.bind(this);
        this.showPaymentHowTo = this.showPaymentHowTo.bind(this);
        this.checkAllowIbon = this.checkAllowIbon.bind(this);

        this.state = {
            paytype: null,
            isEmpty: false,
            isLoading: true,
            isPaying: false,
            price: 0,
            isFromCart: true,
            allowIbon: false,
            allowFami: false,
            allowHilife: false,
            allowAllpay: false,
            isChecked: false,
            minfoisLoading : false,

        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        const current = CupoyRouter.getCurrent();
        const statename = current.state.name;
        var isFromCart = true;
        const minfolastupdtime =  this.props.minfolastupdtime;

        if(statename === CupoyRouter.STATES.PRODUCTCHECKOUT) {
            isFromCart = false;
        }

        this.setState({
            isFromCart,
        }, () => {
            if(isFromCart) {
                this.props.cardCartActions.listCardCartItems().then((result)=>{
                    this.initCartItems();
                });
            } else {
                this.initVitemCartOrder();
            }
        });
        const now = new Date();
        const timeinterval = now - minfolastupdtime;
        //console.log('now ' + now + ' minfolastupdtime ' + minfolastupdtime +' timeinterval ' + timeinterval + " 10min " + 10 * 60 * 1000)
        if(timeinterval > 10 * 60 * 1000){
            this.setState({minfoisLoading : true});
            this.props.memberActions.getMemberPageMetrics().then(()=>{
                    // console.log("update memberinfo  this.props.memberinfo.isover30days " + this.props.memberinfo.isover30days + "this.props.memberinfo.isvip " +  this.props.memberinfo.isvip);
                    this.setState({minfoisLoading : false});
               });;

        }
        else 
            this.setState({minfoisLoading : false});

    }

    checkWebState = () => {
        this.props.webActions.checkWebState(
        ).then(()=> {

            const {
                CATHYATM,
                ESUNATM,
                FAMIPORT,
                LIFEET,
                ALLPAY,
                ECPAY,
            } = this.props;

            const {
                allowFami,
                allowHilife,
                isFromCart,
            } = this.state;

            // 檢查完後將付款方式選到預設第一筆 (Ibon後面還會判斷，不用管)
            let paytype = null;
            if (CATHYATM) {
                paytype = GLOBAL.PAYTYPE.CATHYATM;
            } else if (ESUNATM) {
                paytype = GLOBAL.PAYTYPE.ESUN;
            } else if (allowFami && FAMIPORT) {
                paytype = GLOBAL.PAYTYPE.FAMIPORT;
            } else if (allowHilife && LIFEET) {
                paytype = GLOBAL.PAYTYPE.HILIFE;
            } else if (isFromCart && ALLPAY) {
                paytype = GLOBAL.PAYTYPE.ALLPAY_CREDITCARD;
            } else if (ECPAY) {
                paytype = GLOBAL.PAYTYPE.ECPAYATM;
            }

            this.setState({
                isChecked: true,
                paytype,
            }, () => {
                this.checkAllowIbon();
                this.checkAllowAllpay();

            });
        });
    }

    checkAllowIbon() {

        const {
            IBON,
            cartItems = [],
        } = this.props;

        const {
            price,
        } = this.state;

        // 後台沒有開放IBON，不繼續做下一步檢查
        if (!IBON) {
            return;
        }

        // 購物車商品總價不符合icon繳費範圍，不繼續做下一步檢查
        if (price < 100 || price > 10000) {
            return;
        }

        // 檢查購物車商品是否有除了虛寶包以外的商品，有的話不開放ibon
        const hadOtherPdt = cartItems.reduce((result, item) => 
            result ? result : item.productclassid !== productUtil.PRODUCT_TYPE.VITEM
            , false
        );
        
        // 有除了虛寶包以外的商品，不繼續做下一步檢查
        if (hadOtherPdt) {
            return;
        }

        // 檢查ibon黑名單
        this.props.cardActions.getIbonBlacklist().then(result => {

            const hadBlockPdt = cartItems.reduce((acc, item) =>
                acc ? acc : result.includes(item.jsdproductid)
                , false
            );

            // 沒有商品在黑名單內，開啟ibon選項
            if (!hadBlockPdt) {
                this.setState(prevState => ({
                    // 其他金流都關閉的話不會付款方式不會有預設值，這邊就幫他自動選取到 ibon
                    paytype: prevState.paytype ?? GLOBAL.PAYTYPE.IBON,
                    allowIbon: true,
                }));
            }
        });
    }

    // ------------------------------
    // initCartItems
    // ------------------------------
    initCartItems() {

        const cartItems = this.props.cartItems;

        if(!Array.isArray(cartItems) || cartItems.length === 0) {
            this.setState({
                isEmpty: true,
                isLoading: false,
            });
        } else {

            var price = 0;

            for(var i = 0; i < cartItems.length; i++) {
                price += cartItems[i].memberprice * cartItems[i].count;
            }

            const allowFami = price >= 100 && price <= 5000;
            const allowHilife = price >= 100 && price <= 10000;

            this.setState({
                price: price,
                isLoading: false,
                allowFami,
                allowHilife,
            }, () => {
                // check後台金流開放狀態
                this.checkWebState();
            });
        }
    }

        /**
         * #905
         * 信用卡新規則
         * ■ 新會員30天內不能使用信用卡。
         * ■ 會員每日信用卡購買上限1萬元整(淩晨00:00點重置額度)。
         * ■ 購物車金額1~10000元才顯示信用卡選項
         */

    checkAllowAllpay = () => {
        //console.log('checkAllowAllpay...');
        const {
            ALLPAY,
            memberinfo,
        } = this.props;

        const {
            price,
            isFromCart
        } = this.state;
        // 後台沒有開放信用卡，不繼續做下一步檢查
        if (!ALLPAY) {
            return;
        }
        // 購物車商品總價不符合信用卡繳費範圍，不繼續做下一步檢查
        if (price <= 0 || price > 10000) {
            return;
        }

        // 註冊未超過30天不符合信用卡繳費範圍，不繼續做下一步檢查
        while (!this.state.minfoisLoading){
        // 註冊未超過30天不符合信用卡繳費範圍，不繼續做下一步檢查
            var after30days = parseInt(memberinfo.registertime) + 2592000000;
            if(after30days > Date.now()){
                return;
            }
            // 
            break;

            }
    
            this.setState(prevState => {
                return ({
                    // 其他金流都關閉的話不會付款方式不會有預設值，這邊就幫他自動選取到 ibon
                    paytype: prevState.paytype ?? GLOBAL.PAYTYPE.ALLPAY_CREDITCARD,
                    allowAllpay: true,
                })
            });
    
    

    }

    // ------------------------------
    // initVitemCartOrder
    // ------------------------------
    initVitemCartOrder() {

        const {
            vitemCartOrder,
        } = this.props;

        if(!vitemCartOrder || !vitemCartOrder[0] || !vitemCartOrder[0].totalamount) {
            this.setState({
                isEmpty: true,
                isLoading: false,
            });
        } else {
            const price = vitemCartOrder[0].totalamount;
            const allowFami = price >= 100 && price <= 5000;
            const allowHilife = price >= 100 && price <= 10000;

            this.setState({
                price,
                isLoading: false,
                allowFami,
                allowHilife,

            })
        }
    }

    // ------------------------------
    // payCardCart
    // ------------------------------
    payCardCart() {

        const {
            paytype,
            isPaying,
            isFromCart,
        } = this.state;

        if(isPaying || !paytype) {
            return null;
        }

        this.setState({
            isPaying: true,
        }, () => {

            if(isFromCart) {
                this.props.cardCartActions.payCardCart({
                    paytype: paytype,
                }).then(result => {

                    // if(result.paytype === GLOBAL.PAYTYPE.ALLPAY_CREDITCARD && result.allpaycheckoutstruct) {
                    //     this.props.webActions.payByCreditCard(result.allpaycheckoutstruct);
                    // } else {
                        // 更新未付款購物車列表
                        this.props.cardCartActions.listCardCartUnfinishedOrders({
                            start: 0,
                            refresh: true,
                        });
                        this.props.cardCartActions.listCardCartItems();
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.CART_PAYMENT_INFO, {
                            id: result.cartid,
                        })
                    // }

                }, (error) => {
                    var errorMsg = '發生無法預期的錯誤';
                    var errorDescription = '';

                    if(error && error.code) {
                        errorMsg = error.message;
                    }

                    this.props.webActions.openAlertDialog({
                        title: errorMsg,
                        description: errorDescription,
                    });

                    this.setState({
                        isPaying: false,
                    });
                })
            } else {
                this.props.vitemActions.payVItemCart({
                    paytype: paytype,
                }).then((result) => {

                    // if(result.paytype === GLOBAL.PAYTYPE.ALLPAY_CREDITCARD && result.allpaycheckoutstruct) {
                    //     this.props.webActions.payByCreditCard(result.allpaycheckoutstruct);
                    // } else {
                        this.props.webActions.cupoyStateGo(CupoyRouter.STATES.PRODUCT_PAYMENT_INFO, {
                            id: result.cartid,
                        })
                    // }

                }, (error) => {
                    var errorMsg = '發生無法預期的錯誤';
                    var errorDescription = '';

                    if(error && error.code) {
                        errorMsg = error.message;
                    }

                    this.props.webActions.openAlertDialog({
                        title: errorMsg,
                        description: errorDescription,
                    });

                    this.setState({
                        isPaying: false,
                    });
                })
            }
        })
    }

    // ------------------------------
    // changePaytype
    // ------------------------------
    changePaytype(paytype) {

        this.setState({
            paytype: paytype,
        })
    }

    showPaymentHowTo(paytype) {
        // console.log("paytype", paytype)
        switch(paytype){
            case GLOBAL.PAYTYPE.IBON: {
                this.props.webActions.openAlertDialog({
                    title: 'iBon付款說明',
                    imageUrl: `${GLOBAL.URL_PREFIX}/images/ibon_howto.jpg`,
                });
                break;
            }
            case GLOBAL.PAYTYPE.HILIFE: {
                this.props.webActions.openAlertDialog({
                    title: '萊爾富付款流程',
                    imageUrl: `${GLOBAL.URL_PREFIX}/images/9199_hilife.jpg`,
                });
                break;
            }
            case GLOBAL.PAYTYPE.FAMIPORT: {
                this.props.webActions.openAlertDialog({
                    title: '全家付款流程',
                    imageUrl: `${GLOBAL.URL_PREFIX}/images/famiport_howto.jpg`,
                });
                break;
            }
            case GLOBAL.PAYTYPE.ALLPAY_CREDITCARD: 
                this.props.webActions.openAlertDialog({
                    title: '信用卡付款流程',
                    imageUrl: `${GLOBAL.URL_PREFIX}/images/creditcard_howto.jpg`,
                });
                break;
            case GLOBAL.PAYTYPE.ESUN: 
            case GLOBAL.PAYTYPE.MEGA: 
            case GLOBAL.PAYTYPE.ECPAYATM: 
            case GLOBAL.PAYTYPE.CATHYATM: 
                this.props.webActions.openAlertDialog({
                    title: 'ATM付款流程',
                    imageUrl: `${GLOBAL.URL_PREFIX}/images/atm_howto.jpg`,
                });
                break;
            default:
            break;
        }


    }

    // ------------------------------
    // renderContent
    // ------------------------------
    renderContent() {

        const {
            paytype,
            price,
            isPaying,
            isFromCart,
            allowIbon,
            allowFami,
            allowHilife,
            allowAllpay,

        } = this.state;

        const {
            ESUNATM,
            CATHYATM,
            LIFEET,
            ALLPAY,
            ECPAY,
            FAMIPORT,
        } = this.props;

        return (
            <div>
                <ContentWrapper>
                    <Title>
                        付款方式
                    </Title>

                    {CATHYATM && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.CATHYATM}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.CATHYATM)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.CATHYATM]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.CATHYATM)}>說明</Instructions>
                        </SelectItem>
                    )}

                    {ESUNATM && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.ESUN}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.ESUN)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.ESUN]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.ESUN)}>說明</Instructions>
                        </SelectItem>
                    )}
                    {/* <SelectItem>
                        <SelectTitle
                            active={paytype === GLOBAL.PAYTYPE.MEGA}
                            onClick={() => this.changePaytype(GLOBAL.PAYTYPE.MEGA)}
                        >
                            {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.MEGA]}
                        </SelectTitle>
                        <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.MEGA)}>說明</Instructions>
                    </SelectItem> */}

                    {allowIbon && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.IBON}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.IBON)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.IBON]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.IBON)}>說明</Instructions>
                        </SelectItem>
                    )}

                    {allowFami && FAMIPORT && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.FAMIPORT}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.FAMIPORT)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.FAMIPORT]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.FAMIPORT)}>說明</Instructions>
                        </SelectItem>
                    )}

                    {allowHilife && LIFEET && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.HILIFE}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.HILIFE)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.HILIFE]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.HILIFE)}>說明</Instructions>
                        </SelectItem>
                    )}

                    {allowAllpay && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.ALLPAY_CREDITCARD}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.ALLPAY_CREDITCARD)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.ALLPAY_CREDITCARD]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.ALLPAY_CREDITCARD)}>說明</Instructions>
                        </SelectItem>
                    )}

                    {ECPAY && (
                        <SelectItem>
                            <SelectTitle
                                active={paytype === GLOBAL.PAYTYPE.ECPAYATM}
                                onClick={() => this.changePaytype(GLOBAL.PAYTYPE.ECPAYATM)}
                            >
                                {GLOBAL.PAYTYPE_STRING[GLOBAL.PAYTYPE.ECPAYATM]}
                            </SelectTitle>
                            <Instructions onClick={()=> this.showPaymentHowTo(GLOBAL.PAYTYPE.ECPAYATM)}>說明</Instructions>
                        </SelectItem>
                    )} 
                    <Prompt>
                        提示： <br/>
                        {`1. 訂單繳款期限${GLOBAL.PAYMENT_LIMIT_HOUR_TEXT}(逾期失敗)`} <br/>
                        2.若繳費金額錯誤，無法出貨只能退費
                    </Prompt>
                </ContentWrapper>

                <StepBottomBar.StepBarWrapper>
                    <StepBottomBar.StepBarText>
                        總價：
                        <StepBottomBar.StepBarPrice>
                            {price}元
                        </StepBottomBar.StepBarPrice>
                    </StepBottomBar.StepBarText>
                    <StepBottomBar.StepBarButton
                        onClick={this.payCardCart}
                        disable={isPaying || !paytype}
                    >
                        {isPaying && (
                            <GlobalStyle.Spin color="#fff" size="16px" margin="0 10px 0 0"/>
                        )}
                        下一步
                    </StepBottomBar.StepBarButton>
                </StepBottomBar.StepBarWrapper>
            </div>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {
        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            isLoading,
            isEmpty,
            isChecked,
        } = this.state;

        return (
            <div>
                {!isLoading && !isEmpty && isChecked && this.renderContent()}

                {isLoading || !isChecked ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin/>
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }
                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            購物車無商品，無法結帳
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </div>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            isFromCart,
        } = this.state;

        const {
            haslogin,
        } = this.props;
        
        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }
        
        return (
            <GlobalStyle.Container
                hasFixedTopbar
                hasFixedTabbar={false}
                backgroundColor='#fff'
            >
                <TopbarView
                    isFixed
                    leftComponent={
                        <CupoySrefView
                            statename={isFromCart ? CupoyRouter.STATES.CART : CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            {isFromCart ? '購物車' : '會員中心'}
                        </CupoySrefView>
                    }
                    middleComponent="結帳"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    export
--------------------------*/

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        cartItems: state.cardcart.cartItems,
        vitemCartOrder: state.vitem.cartOrder,
        memberinfo: state.member.memberinfo,
        minfolastupdtime : state.member.minfolastupdtime,
        ALLPAY: state.web.ALLPAY,
        CATHYATM: state.web.CATHYATM,
        ECPAY: state.web.ECPAY,
        ESUNATM: state.web.ESUNATM,
        FAMIPORT: state.web.FAMIPORT,
        IBON: state.web.IBON,
        LIFEET: state.web.LIFEET,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        webActions: bindActionCreators(actionCreators.webActionCreators, dispatch),
        cardCartActions: bindActionCreators(actionCreators.cardCartActionCreators, dispatch),
        cardActions: bindActionCreators(actionCreators.cardActionCreators, dispatch),
        vitemActions: bindActionCreators(actionCreators.vitemActionCreators, dispatch),
        memberActions: bindActionCreators(actionCreators.memberActionCreators, dispatch),

    };
};

/*--------------------------
    export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(CheckoutView);
