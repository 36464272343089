
// 身分證領補換類別代碼
const ID_CARD_STATUS = {
    '初發': 1,
    '補發': 2,
    '換發': 3,
};

// 行政區代碼
const DISTRICT_CODE = {
    '北縣': '10001',
    '宜縣': '10002',
    '桃縣': '10003',
    '竹縣': '10004',
    '苗縣': '10005',
    '中縣': '10006',
    '彰縣': '10007',
    '投縣': '10008',
    '雲縣': '10009',
    '嘉縣': '10010',
    '南縣': '10011',
    '高縣': '10012',
    '屏縣': '10013',
    '東縣': '10014',
    '花縣': '10015',
    '澎縣': '10016',
    '基市': '10017',
    '竹市': '10018',
    '嘉市': '10020',
    '連江': '09007',
    '金門': '09020',
    '北市': '63000',
    '高市': '64000',
    '新北市': '65000',
    '中市': '66000',
    '南市': '67000',
    '桃市': '68000',
};

export default {
    ID_CARD_STATUS, // 身分證領補換類別代碼
    DISTRICT_CODE, // 行政區代碼
};