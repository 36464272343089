/*=====================================
    MemberCardOrderDetailList

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actionCreators from 'actions/creators';
import styled from 'styled-components';
import { GlobalStyle, Colors, MemberStyle } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import CupoyListRepeat from 'components/commons/CupoyListRepeat';
import TimeFormat from "components/commons/TimeFormat";
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const Content = styled.div`
    padding: 0 0 10px 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberCardOrderDetailListView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);

        this.state = {
            start: 0,
            isLoading: false,
            isError: false,
            isEmpty: false,
            isNoMore: false,
        };

        this.qyCardOrderDetail = this.qyCardOrderDetail.bind(this);
        this.rowRenderer = this.rowRenderer.bind(this);
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {
        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if (prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        const {
            haslogin,
            jcardIdsByOrderId,
        } = props;

        const current = CupoyRouter.getCurrent();
        const {
            params,
        } = current;

        const id = params.id;

        if(!id || !haslogin || this.state.id === id) {
        	return;
        }

        const jcardIds = jcardIdsByOrderId[id];
        const start = Array.isArray(jcardIds) ? jcardIds.length : 0;

        this.setState({
        	id: id,
        	start: start,
        }, () => {
        	if(start === 0) {
        		this.qyCardOrderDetail();
        	}
        })
    }

    // ------------------------------
    // 取得點卡/產包訂單列表
    // ------------------------------
    qyCardOrderDetail() {

        const {
        	id,
            start,
            isLoading,
            isEmpty,
            isNoMore,
            isError,
        } = this.state;

        if (isLoading || isEmpty || isNoMore || isError) {
            return;
        }

        this.setState({
            isLoading: true,
        }, () => {
            this.props.cardOrderActions.qyCardOrderDetail({
            	orderid: id,
                start: start,
            }).then((result) => {

            	const {
            	    jcardIdsByOrderId,
            	} = this.props;

            	const jcardIds = jcardIdsByOrderId[id];
                
                var newState = {
                    isLoading: false,
                    start: jcardIds.length,
                };

                if (!Array.isArray(jcardIds) || jcardIds.length === 0) {
                    newState.isEmpty = true;
                } else if (start === newState.start) {
                    newState.isNoMore = true;
                }

                this.setState(newState);
            }, (error) => {
                this.setState({
                    isLoading: false,
                    isError: true,
                });
            })
        })
    }

    // ------------------------------
    // rowRenderer
    // ------------------------------
    rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style, // Style object to be applied to row (to position it),
        parent,
        measure,
        columnIndex,
        updateRowSize,
        measureCache,
        listWidth,
    }, width, listHeight) {

    	const {
        	id,
        } = this.state;

        const {
            jcardMap,
            jcardIdsByOrderId,
        } = this.props;

        const jcardIds = jcardIdsByOrderId[id];
        const jcardId = jcardIds[index];
        const jcard = jcardMap[jcardId];

        if (!jcard) {
            return null;
        }

        return (
            <CupoySrefView
                statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_DETAIL}
                params={{
                    id: id,
                    jcardid: jcard.jcardid,
                }}
                key={index}
                style={style}
            >
                <MemberStyle.LogsItem hasArrow={true}>
                    <MemberStyle.LogsTitle>
                        {jcard.productname}
                    </MemberStyle.LogsTitle>
                    <MemberStyle.LogsDescription>
                        有效期限: {jcard.expiredtime > -1 ? <TimeFormat format="simpleFullTime" time={jcard.expiredtime} /> : '無有效期限'}
                    </MemberStyle.LogsDescription>
                    <MemberStyle.LogsDescription isLast={true}>
                        點數編號: {jcard.jcardid}
                    </MemberStyle.LogsDescription>
                    {jcard.isused ?
                        <MemberStyle.LogsTag>已使用</MemberStyle.LogsTag>
                        :
                        <MemberStyle.LogsTag color={Colors.Orange}>未使用</MemberStyle.LogsTag>
                    }
                    <MemberStyle.LogsRightArrow><i className="icon-chevron-right"/></MemberStyle.LogsRightArrow>
                </MemberStyle.LogsItem>
            </CupoySrefView>
        )
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
        	id,
            isLoading,
            isError,
            isEmpty,
        } = this.state;

        const {
            jcardIdsByOrderId,
        } = this.props;

        const jcardIds = jcardIdsByOrderId[id];

        return (
            <Content>
                {Array.isArray(jcardIds) && jcardIds.length > 0 ?
                    <CupoyListRepeat
                        listId={this.constructor.name}
                        rowRenderer={this.rowRenderer}
                        items={jcardIds}
                        isLoading={isLoading}
                        useWindowScroll={true}
                        restoreWindowScroll={true}
                        loadMoreRows={() => this.qyCardOrderDetail()}
                    />
                    :
                    null
                }

                {isLoading ?
                    <GlobalStyle.LoadingContainer>
                        <GlobalStyle.Spin />
                    </GlobalStyle.LoadingContainer>
                    :
                    null
                }

                {isError ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            發生錯誤
                        </GlobalStyle.ErrorContainerTitle>
                        <GlobalStyle.ErrorContainerDesription>
                            無法取得訂單資訊，請重新整理後再嘗試。
                        </GlobalStyle.ErrorContainerDesription>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }

                {isEmpty ?
                    <GlobalStyle.ErrorContainer>
                        <GlobalStyle.ErrorContainerTitle>
                            無任何訂單資訊
                        </GlobalStyle.ErrorContainerTitle>
                    </GlobalStyle.ErrorContainer>
                    :
                    null
                }
            </Content>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            點卡產包
                        </CupoySrefView>
                    }
                    middleComponent="訂單點卡列表"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        jcardMap: state.cardorder.jcardMap,
        jcardIdsByOrderId: state.cardorder.jcardIdsByOrderId,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
        cardOrderActions: bindActionCreators(actionCreators.cardOrderActionCreators, dispatch),
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberCardOrderDetailListView);