/*=====================================
    MemberCardOrderList

    Author: Gray
    CreateTime: 2018 / 03 / 15
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import TopbarView from "components/commons/TopbarView";
import CupoyRouter from "components/commons/router/CupoyRouter";
import CupoySrefView from "components/commons/router/CupoySrefView";
import MemberCardOrderListFinishViewComponent from 'components/member/card/MemberCardOrderListFinishView';
import MemberCardOrderListCartViewComponent from 'components/member/card/MemberCardOrderListCartView';
import NeedLoginBlock from 'components/commons/status/NeedLoginBlock';

/*--------------------------
    Styled
--------------------------*/
const HeaderHeight = 50;
const Header = styled.div`
    display: flex;
    position: fixed;
    top: ${GlobalStyle.TopbarHeight}px;
    left: 0;
    width: 100%;
    height: ${HeaderHeight}px;
    background-color: #FFFFFF;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
    z-index: 1;
`;
const NavItem = styled.div`
    display: inline-block;
    width: 50%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: ${(props) => props.active ? Colors.Orange : Colors.Dark5};
    text-align: center;
    cursor: pointer;
`;
const SubContent = styled.div`
    padding: ${HeaderHeight}px 0 0 0;
`;

/*--------------------------
    Main Component
--------------------------*/
class MemberCardOrderListView extends PureComponent {

    // ------------------------------
    // constructor
    // ------------------------------
    constructor(props) {

        super(props);
        
        
        this.state = {
            MemberCardOrderListFinishViewComponent,
            MemberCardOrderListCartViewComponent,
        };
    }

    // ------------------------------
    // componentDidMount
    // ------------------------------
    componentDidMount() {

        this.init(this.props);
    }

    // ------------------------------
    // componentWillReceiveProps
    // ------------------------------
    componentDidUpdate(prevProps) {

        if(prevProps.haslogin !== this.props.haslogin) {
            this.init(this.props);
        }
    }

    // ------------------------------
    // [private] 初始化
    // ------------------------------
    init(props) {

        if(props.haslogin) {
            this.lazyLoadingComponent();
        }
    }

    // ------------------------------
    // lazyLoadingComponent
    // ------------------------------
    lazyLoadingComponent() {

        // import('components/member/card/MemberCardOrderListFinishView').then((MemberCardOrderListFinishModule) => {
        //     this.setState({ MemberCardOrderListFinishViewComponent: MemberCardOrderListFinishModule.default });
        // }).catch(err => { console.log(err) });
        // import('components/member/card/MemberCardOrderListCartView').then((MemberCardOrderListCartModule) => {
        //     this.setState({ MemberCardOrderListCartViewComponent: MemberCardOrderListCartModule.default });
        // }).catch(err => { console.log(err) });
    }

    // ------------------------------
    // 未登入的畫面
    // ------------------------------
    renderNoLoginContent() {

        return <NeedLoginBlock />;
    }

    // ------------------------------
    // 已登入的畫面
    // ------------------------------
    renderLoginContent() {

        const {
            MemberCardOrderListFinishViewComponent,
            MemberCardOrderListCartViewComponent,
        } = this.state;

        return (
            <div>
                <Header>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH}
                        stylecomponent={NavItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_FINISH)}
                        querys={CupoyRouter.getCurrent().querys ? CupoyRouter.getCurrent().querys : {}}
                    >
                        訂單
                    </CupoySrefView>
                    <CupoySrefView 
                        statename={CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_CART}
                        stylecomponent={NavItem}
                        active={CupoyRouter.isActive(CupoyRouter.STATES.MEMBER_CARD_ORDER_LIST_CART)}
                    >
                        購物車
                    </CupoySrefView>
                </Header>
                <SubContent>
                    {MemberCardOrderListFinishViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_LIST_FINISH} component={MemberCardOrderListFinishViewComponent} exact/> : null}
                    {MemberCardOrderListCartViewComponent ? <Route path={CupoyRouter.PATHS.MEMBER_CARD_ORDER_LIST_CART} component={MemberCardOrderListCartViewComponent} exact/> : null}
                </SubContent>
            </div>
        )
    }

    // ------------------------------
    // render
    // ------------------------------
    render() {

        const {
            haslogin,
        } = this.props;

        var content;

        if(haslogin) {
            content = this.renderLoginContent();
        } else {
            content = this.renderNoLoginContent();
        }

        return (
            <GlobalStyle.Container 
                hasFixedTopbar={true} 
                hasFixedTabbar={false}
                backgroundColor='#f5f5f5'
            >
                <TopbarView
                    isFixed={true}
                    leftComponent={
                        <CupoySrefView 
                            statename={CupoyRouter.STATES.MEMBER}
                            stylecomponent={GlobalStyle.TopbarButton}
                        >   
                            <GlobalStyle.TopbarIcon>
                                <i className="zmdi zmdi-chevron-left"></i>
                            </GlobalStyle.TopbarIcon>
                            返回
                        </CupoySrefView>
                    }
                    middleComponent="點卡產包"
                />
                {content}
            </GlobalStyle.Container>
        );
    }
}

/*--------------------------
    Reducer Props
--------------------------*/
const mapStateToProps = function(state) {
    return {
        haslogin: state.member.haslogin,
        orderMap: state.cardorder.orderMap,
        orderIds: state.cardorder.orderIds,
    };
};

/*--------------------------
    Reducer Action
--------------------------*/
const mapActionToProps = function(dispatch) {
    return {
    };
};

/*--------------------------
    Export
--------------------------*/
export default connect(mapStateToProps, mapActionToProps, null, {
    forwardRef: true
})(MemberCardOrderListView);