/**
|--------------------------------------------------
| 會員條款
| @author Ac
|--------------------------------------------------
*/
import React from 'react';
import styled from 'styled-components';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import TopbarView from "components/commons/TopbarView";
import {
  AboutStyle,
  GlobalStyle,
} from 'stylecomponents';

/**
|--------------------------------------------------
|  Variables
|--------------------------------------------------
*/
const DATA_LIST = [
	{
		title: '遵守會員規範',
		description: '在您於『9199 交易久久』 註冊成為會員後，可以使用『9199 交易久久』 所提供之各種服務。當您使用『9199 交易久久』 時，即表示同意接受『9199 交易久久』 之會員規範及所有注意事項之約束。',
	},
	{
		title: '使用服務',
		description: '基於『9199 交易久久』 所提供之各項服務，您同意於註冊時提供完整詳實且符合真實之個人資料，您所登錄之資料事後若有變更時，應隨時更新。',
	},
	{
		title: '個人資料之保護',
		description: `對於會員所登錄或留存之個人資料，『9199 交易久久』 在未獲得會員同意以前，決不對外揭露會員之姓名、地址、電子郵件地址及其他依法受保護之個人資料。下述幾種特殊情況不受此限：\n(1)基於法律之規定\n(2)受司法機關或其他有權機關基於法定程序之要求\n(3)為保障『9199 交易久久』 之財產及權益\n(4)在緊急情況下為維護其他會員或第三人之人身安全`,
	},
	{
		title: '個人資料之運用',
		description: `對於會員所登錄或留存之個人資料，『9199 交易久久』 得於合理之範圍內運用該等資料，以提供使用者其他資訊或服務，或作成會員統計資料以進行關於網路行為之調查或研究。未經使用者主動註冊所產生的資料，『9199 交易久久』 僅對使用者行為進行總和行為之分析，不會對個別使用者進行分析。`,
	},
	{
		title: '個人資料之填寫',
		description: `您提供之個人資料若有填寫不實或原登錄之資料不實而未更新，或是有任何誤導之嫌，『9199 交易久久』 保留隨時終止您會員資格及使用『9199 交易久久』 之權利。`,
	},
	{
		title: '會員的義務與責任',
		description: `會員對本身於『9199 交易久久』或透過『9199 交易久久』傳輸的一切內容自負全責。\n(1)會員承諾遵守中華民國相關法規及一切國際網際網路規定與慣例。\n(2)會員同意並保證不公布或傳送任何毀謗、不實、威脅、不雅、猥褻、不法、攻擊性、毀謗性或侵害他人智慧財產權的文字，\n圖片或任何形式的檔案於『9199 交易久久』。\n(3)會員同意不會於『9199 交易久久』上從事廣告或販賣商品行為。\n(4)會員同意避免在公眾討論區討論私人事務，發表文章時，請尊重他人的權益及隱私權。\n(5)會員同意必須充份尊重著作權，禁止發表侵害他人各項智慧財產權之文字、圖片或任何形式的檔案。\n(6)會員同意9199可隨時因應交易安全與預防詐騙，暫時停止會員權限並進行交易抽查與實名驗證，會員同意提供身分證、健保卡、銀行存摺等個人證件供實名驗證。`,	},
	{
		title: '禁止從事違反法律規定之行為',
		description: `『9199 交易久久』 就會員的行為是否符合會員規範有最終決定權。若『9199 交易久久』決定會員的行為違反本會員規範或任何法令，會員同意『9199 交易久久』得隨時停止帳號使用權或清除帳號，並停止使用『9199 交易久久』。會員在違反法律規定之情事，應自負法律責任。`,
	},
	{
		title: '服務之停止與更改',
		description: `(1)於發生下列情形之一時，『9199 交易久久』 有權停止或中斷提供服務：\n* 對『9199 交易久久』 之設備進行必要之保養及施工時；\n* 發生突發性之設備故障時；\n* 由於『9199 交易久久』 所申請之ISP業者無法提供服務時；\n* 因天災等不可抗力之因素致使『9199 交易久久』無法提供服務時。\n(2)『9199 交易久久』 可能因公司或ISP業者網路系統軟硬體設備之故障、失靈或人為操作上之疏失而造成全部或部份中斷、暫時無法使用、延遲或造成資料傳輸或儲存上之錯誤、或遭第三人侵入系統篡改或偽造變造資料等，會員不得因此而要求任何補償。`,
	},
	{
		title: '保管及通知義務',
		description: `您有責任維持密碼及帳號的機密安全。您必須完全負起因利用該密碼及帳號所進行之一切行動之責任。當密碼或帳號遭到未經授權之使用，或發生其他任何安全問題時，您必須立即通知『9199 交易久久』 ，每次您連線完畢，均要結束您的帳號使用。因您未遵守本項約定所生之任何損失或損害，我們將無法亦不予負責。`,
	},
	{
		title: '特別同意事項',
		description: `您同意於『9199 交易久久』 所發表之一切內容僅代表您個人之立場與行為， 並同意承擔所有相關衍生之責任，『9199 交易久久』 不負任何責任。`,
	},
	{
		title: '擔保責任免除',
		description: `(1)『9199 交易久久』 保留隨時停止、更改各項服務內容或終止任一會員帳號使用之權利，無需事先通知會員本人。無論任何情形，就停止或更改服務或終止會員帳號使用所可能產生之困擾、不便或損害，『9199 交易久久』 對任何會員或第三人均不負任何責任\n(2)『9199 交易久久』 保留將來新增、修改或刪除各項服務之全部或一部之權利，且不另行個別通知，會員不得因此而要求任何補償或賠償。\n(3)對於透過『9199 交易久久』 銷售之商品，非由『9199 交易久久』 本身提供者，『9199 交易久久』 對其交易過程及商品本身均不負任何擔保責任。會員透過『9199 交易久久』 所購得之商品或服務，完全由供應商負全責，若有任何瑕疵或擔保責任，均與『9199 交易久久』 無關。`,
	},
	{
		title: '廣告或促銷行為',
		description: `『9199 交易久久』上有關商業廣告及各種商品之促銷資訊,該等內容均係由廣告商或商品服務提供人所為，『9199 交易久久』僅係提供刊登內容之媒介。會員透過『9199 交易久久』上所提供之商品、服務資訊，所購買之任何商品或服務，其間交易關係均存在於會員與商品或服務提供人間，與『9199 交易久久』無關。`,
	},
	{
		title: '智慧財產權',
		description: `『9199 交易久久』 刊出之所有著作及資料（例如文章、圖片等）內容，其著作權、專利權、商標權、營業秘密及其他智慧財產權，皆為『淞果數位股份有限公司』或該內容之提供者所有，且受中華民國著作權法令及國際著作權法律的保障。『9199 交易久久』畫面資料之選擇、編排之版權為『淞果數位股份有限公司』所有，且受中華民國著作權法令及國際著作權法律的保障。非經本公司書面授權同意，不得以任何形式轉載、傳輸、傳播、散布、展示、出版、再製或利用『9199 交易久久』 內容的局部、全部的內容，以免觸犯相關法律規定。未經本公司書面同意，您不得擅自複製、進行還原工程（reverse engineering）、解編（de-compile）或反向組譯（disassemble）『9199 交易久久』之任何功能或程式。`,
	},
	{
		title: '連結',
		description: `『9199 交易久久』 在網站或相關網頁上所提供之所有連結，可能連結到其他個人、公司或組織之網站，提供該連結之目的，僅為便利站友搜集或取得資訊，『9199 交易久久』 對於被連結之該等個人、公司或組織之網站上所提供之產品、服務或資訊，既不擔保其真實性、完整性、即時性或可信度，該等個人、公司或組織亦不因此而當然與『9199 交易久久』 有任何僱佣、委任、代理、合夥或其他類似之關係。`,
	},
	{
		title: '損害賠償',
		description: `因會員違反相關法令或違背本同意書之任一條款，致『9199 交易久久』 或其關係企業、受僱人、受託人、代理人及其他相關履行輔助人因而受有損害或支出費用（包括且不限於因進行民事、刑事及行政程序所支出之律師費用）時，會員應負擔損害賠償責任或填補其費用。`,
	},
	{
		title: '會員規範之修改',
		description: `『9199 交易久久』保留隨時修改本會員規範之權利，『9199 交易久久』將於修改會員規範時，於網站首頁公告修改之內容，而不另對會員作個別通知。若會員不同意修改的內容，請勿繼續使用『9199 交易久久』。如果會員繼續使用『9199 交易久久』，將視為會員已同意並接受本規範等增訂或修改內容之約束。`,
	},
	{
		title: '個別條款之效力',
		description: `本同意書所定之任何會員條款之全部或一部無效時，不影響其他條款之效力。`,
	},
	{
		title: '準據法及管轄法院',
		description: `本同意書之解釋及適用以及會員因使用本服務而與『9199 交易久久』 間所生之權利義務關係，應依中華民國法令解釋適用之。其因此所生之爭議，以台灣台北地方法院為第一審管轄法院。`,
	},
];

/**
|--------------------------------------------------
| Styled
|--------------------------------------------------
*/
const Description = styled(AboutStyle.Description)`
	white-space: pre-wrap;
`;

/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/
const MemberTermsView = () => {
  return (
    <GlobalStyle.Container
      hasFixedTopbar
      hasFixedTabbar={false}
      backgroundColor='#f5f5f5'
    >
      <TopbarView
        isFixed
        leftComponent={
          <CupoySrefView
            statename={CupoyRouter.STATES.MEMBER}
            stylecomponent={GlobalStyle.TopbarButton}
          >
            <GlobalStyle.TopbarIcon>
              <i className="zmdi zmdi-chevron-left"></i>
            </GlobalStyle.TopbarIcon>
            返回
          </CupoySrefView>
        }
        middleComponent="會員條款"
      />
      <AboutStyle.Container>
        歡迎來到『9199 交易久久』！<br/>
        為了保障您的權益，在註冊前請先詳細閱讀本同意書之所有內容，<br/>
        當您在點選「同意」後，即視為您已閱讀本同意書，並同意遵守以下所有同意書之規範。<br/>
        若您不同意以下所述內容，請暫時不要參與我們網站上的活動。 
        {
          DATA_LIST.map(({
            title,
            description,
          }, index) => (
            <AboutStyle.Section key={index}>
              <AboutStyle.Title>{`${index + 1}. ${title}`}</AboutStyle.Title>
              <Description>{description}</Description>
            </AboutStyle.Section>
          ))
        }
      </AboutStyle.Container>
    </GlobalStyle.Container>
  );
};

export default MemberTermsView;
