import actionTypes from "actions/types/memberActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {
	// 登入成功，接收會員資訊
	[actionTypes.loginSuccess]: function(state, action) {
		return loginSuccess(state, action);
	},
    // 登入逾時 (已被server登出)
	[actionTypes.loginStatusExpired]: function(state, action) {
		return loginStatusExpired(state, action);
	},
    [actionTypes.getMemberAcntInfoSuccess]: function(state, action) {
		return getMemberAcntInfoSuccess(state, action);
	},
    [actionTypes.updMemberSuccess]: function(state, action) {
        return updMemberSuccess(state, action);
    },
    [actionTypes.updEMailAndSendAuthSuccess]: function(state, action) {
        return updEMailAndSendAuthSuccess(state, action);
    },
    [actionTypes.updMobilePhoneAndSendSmSAuthSuccess]: function(state, action) {
        return updMobilePhoneAndSendSmSAuthSuccess(state, action);
    },
    [actionTypes.forgetPasswordSuccess]: function(state, action) {
        return forgetPasswordSuccess(state, action);
    },
    [actionTypes.updPasswordSuccess]: function(state, action) {
        return updPasswordSuccess(state, action);
    },
    [actionTypes.logoutSuccess]: function(state, action) {
        return logoutSuccess(state, action);
    },
    [actionTypes.verifyEmailAuthSuccess]: function(state, action) {
        return verifyEmailAuthSuccess(state, action);
    },
    [actionTypes.verifySmsAuthSuccess]: function(state, action) {
        return verifySmsAuthSuccess(state, action);
    },
    [actionTypes.listLoginHistorysSuccess]: function(state, action) {
        return listLoginHistorysSuccess(state, action);
    },
    [actionTypes.listMemberMsgLogsSuccess]: function(state, action) {
        return listMemberMsgLogsSuccess(state, action);
    },[actionTypes.getMemberPageMetricsSuccess]: function(state, action) {
        return getMemberPageMetricsSuccess(state, action);
    },
    [actionTypes.listTradeLogsSuccess]: function(state, action) {
        return listTradeLogsSuccess(state, action);
    },
    [actionTypes.listRefundApplicationsSuccess]: function(state, action) {
        return listRefundApplicationsSuccess(state, action);
    },
    [actionTypes.addRefundApplicationSuccess]: function(state, action) {
        return addRefundApplicationSuccess(state, action);
    },

    [actionTypes.getCustomerCaseReplySuccess]: function(state, action) {
        return getCustomerCaseReplySuccess(state, action);
    },
    [actionTypes.listCustomerCaseClassesSuccess]: function(state, action) {
        return listCustomerCaseClassesSuccess(state, action);
    },
    [actionTypes.createCustomerCaseSuccess]: function(state, action) {
        return createCustomerCaseSuccess(state, action);
    },
    [actionTypes.listInvoicesSuccess]: function(state, action) {
        return listInvoicesSuccess(state, action);
    },
    [actionTypes.updateMemberAcntInfoToStorge]: function(state, action) {
        return updateMemberAcntInfoToStorge(state, action);
    },
    [actionTypes.getMemberMsgUnReadcntSuccess]: function(state, action) {
        return getMemberMsgUnReadcntSuccess(state, action);
    },
    [actionTypes.setMemberMsgLogReadSuccess]: function(state, action) {
        return setMemberMsgLogReadSuccess(state, action);
    },
    [actionTypes.updIDNumberSuccess]: function(state, action) {
        return updIDNumberSuccess(state, action);
    },
};



/*==========================
    Method
==========================*/

function listInvoicesSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    return Object.assign({}, state, {invoiceList:  [...state.invoiceList, ...dbresult]})
}

function createCustomerCaseSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
        csCaseIds: [],
	});
}
function listCustomerCaseClassesSuccess (state, action) {
    const dbresult = [{csclassid: 0, csclassname: '主分類', bindclassid: -1}, ...convetDBResultSetToArray(action.payload.result)];


    const csClassMap = dbresult.reduce((map, item)=>{
        // find sub-class ids
        const subids = dbresult.filter((subitem)=>{
            return subitem.bindclassid === item.csclassid;
        }).map((subitem)=>{return subitem.csclassid});

        map[item.csclassid] = Object.assign({}, item, {subids});
        return map;
    }, {});
    

    return Object.assign({}, state, {
        csClassMap,
        isloading: false,
	});
}

function getCustomerCaseReplySuccess (state, { payload }) {

    const {
        memberinfo,
        csCaseMap,
    } = state;

    const dbresult = convetDBResultSetToArray(payload.result)[0];

    const newCsCaseMap = {
        ...csCaseMap,
        [dbresult.caseid]: dbresult,
    };
    
    const { readtime } = dbresult;

    const newMemberinfo = {
        ...memberinfo,
        csmsgcnt: readtime < 0 ? memberinfo.csmsgcnt - 1 < 0 ? 0 : memberinfo.csmsgcnt - 1 : memberinfo.csmsgcnt,
    };

    return {
        ...state,
        memberinfo: newMemberinfo,
        csCaseMap: newCsCaseMap,
        isloading: false,
    };
}

function listRefundApplicationsSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);

    const refundApplications = [...state.refundApplications, ...dbresult];
    
    return Object.assign({}, state, {
        refundApplications
    })
}

function addRefundApplicationSuccess (state, action) {
    return Object.assign({}, state, {
        refundApplications: [],
    })
}

function listTradeLogsSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);

    const tradeLogs = [...state.tradeLogs, ...dbresult];
    
    return Object.assign({}, state, {
        tradeLogs
    })
}

function getMemberPageMetricsSuccess (state, action) {
    
    const c2cgr = convetDBResultSetToArray(action.payload.result.c2cgr)[0];
    const vitemgr = convetDBResultSetToArray(action.payload.result.vitemgr)[0];

    const memberInfo = action.payload.result.memberInfo;
    

    const { csmsgcnt } = action.payload.result;

    const memberinfo = Object.assign({}, state.memberinfo, {...c2cgr}, {...memberInfo}, {...vitemgr}, {csmsgcnt});
    const minfolastupdtime =  new Date();
	return Object.assign({}, state, {
		memberinfo,
        minfolastupdtime,
	});
}

function listMemberMsgLogsSuccess (state, action) {
    // msgLogMap: {},
    // msgLogIds: [],    
    const dbresult = action.payload.result.list;

    const itemMap = dbresult.reduce((map, item)=>{
        map[item.msgid] = Object.assign({}, state.msgLogMap[item.msgid], item);
        return map;
    }, {});
    

    const ids = dbresult.map((item, idx)=>{
        return item.msgid;
    });

    const newids = uniq([...state.msgLogIds, ...ids]);

    const newItemMap = Object.assign({}, state.msgLogMap, itemMap);

    return Object.assign({}, state, {
        msgLogIds: newids,
        msgLogMap: newItemMap,
        isloading: false,
	});
}

// 登入成功，接收會員資訊
function loginSuccess (state, action) {

    if(state.haslogin && state.memberinfo === action.payload){
        return state;
    }

    const haslogin = true;

	return Object.assign({}, state, {
        haslogin,
	});

}

// 登入逾時 (已被server登出)
function loginStatusExpired(state) {

    if(!state.haslogin){
        return state;
    }

    return {
        ...state,
        haslogin: false,
    }
}

// 接收會員資訊
function getMemberAcntInfoSuccess (state, action) {

    if(state.memberinfo === action.payload){
        return state;
    }
    const memberinfo = action.payload;

	return Object.assign({}, state, {
        haslogin: true,
		memberinfo,
	});

	
}

function updMemberSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}

function updEMailAndSendAuthSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
        memberinfo: {
            ...state.memberinfo,
            isemailauth: false,
        },
	});
}

function updMobilePhoneAndSendSmSAuthSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
        memberinfo: {
            ...state.memberinfo,
            issmsauth: false,
        },
	});
}

function forgetPasswordSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}

function updPasswordSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}

function logoutSuccess (state, action) {

    if(!state.haslogin){
        return state;
    }

    return Object.assign({}, state, {
        haslogin: false,
        isloading: false,
        error: undefined,
        memberinfo: {},
        loginhistorys: [],
        msgLogMap: {},
        msgLogIds: [],
        tradeLogs: [],
        refundApplications: [],
        csCaseMap: {},
        csCaseIds: [],
        csClassMap: {},
        invoiceList: [],
	});
}

function verifyEmailAuthSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
        memberinfo: {
            ...state.memberinfo,
            isemailauth: true,
        },
	});
}

function verifySmsAuthSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}

function listLoginHistorysSuccess (state, action) {

	const loginhistorys = [...state.loginhistorys, ...convetDBResultSetToArray(action.payload)];
    return Object.assign({}, state, {
        loginhistorys,
        isloading: false,
	});
}



// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}

function updateMemberAcntInfoToStorge (state, { payload }){

    const new_meta = payload;
    const { memberinfo } = state;
    const new_memberInfo = Object.assign({}, memberinfo, new_meta);

    return Object.assign({}, state, {
        memberinfo: new_memberInfo,
    });
}

function getMemberMsgUnReadcntSuccess(state, { payload }) {

    const membermsgcnt = payload;
    const { memberinfo } = state;

    const new_memberinfo = Object.assign({}, memberinfo, {
        membermsgcnt,
    });

    const new_state = Object.assign({}, state, {
        memberinfo: new_memberinfo,
    });

    return new_state;
}

function setMemberMsgLogReadSuccess(state, { payload }) {

    const { msgids = [] } = payload;
    const { memberinfo, msgLogMap } = state;
    const { membermsgcnt = 0 } = memberinfo;

    const new_msgLogMap = Object.keys(msgLogMap).reduce((obj, key) =>
        // 強制轉型比對
        msgids.includes(Number(key)) ?
        Object.assign(
            {},
            obj,
            { [key]: Object.assign({}, msgLogMap[key], { isRead: true }) },
        )
        :
        Object.assign(
            {},
            obj,
            { [key]: msgLogMap[key] },
        )
    , {});

    const new_membermsgcnt = (membermsgcnt - msgids.length) < 0 ? 0 : membermsgcnt - msgids.length;

    const new_memberinfo = Object.assign({}, memberinfo, {
        membermsgcnt: new_membermsgcnt,
    });

    return Object.assign({}, state, {
        memberinfo: new_memberinfo,
        msgLogMap: new_msgLogMap,
    });
}

function updIDNumberSuccess(state, { payload }) {

    const { idnumber } = payload;
    const { memberinfo } = state;

    const new_memberinfo = Object.assign({}, memberinfo, {
        idnumber: idnumber.substring(0, 4) + (idnumber.substring(9).padStart(6, '*')),
    });

    const new_state = Object.assign({}, state, {
        memberinfo: new_memberinfo,
    });

    return new_state;
}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
    
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }

        // console.log('actionTypes.hasOwnProperty(action.type)',actionTypes.hasOwnProperty(action.type), action.type);
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){            
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}